/* eslint-disable no-irregular-whitespace */
/**
 * Type: ページ
 * What: チケットページ
 */
import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import axios from 'axios';
import moment from 'moment/moment';
import Calendar from 'react-calendar';
import ReactModal from 'react-modal';
import Collapsible from 'react-collapsible';

import '../../sass/components/_calendar.scss';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';

import useMediaQuery from '../../util/useMediaQuery';
import { Button } from '../../components/Btn';

const mq = useMediaQuery;

const pageTitle = 'チケット空席情報';
const pageDescription = 'E・ZO FUKUOKAのチケット空席情報ページです。4階王貞治ベースボールミュージアム、6階イベントホール、6階V-World AREA、RF絶景3兄弟 SMBC日興証券（すべZO SMBC日興証券・つりZO SMBC日興証券・のぼZO SMBC日興証券）のチケット空席をご確認いただけます。';
const pageSlug = 'ticket';
const TICKET_ENDPOINT = (locale) => `/api-data/ticket_${locale}.json`;
const HOLIDAY = '/api-data/holiday.json';
const RANK = '/api-data/rank.json';

export const query = graphql`
  {
    site {
      siteMetadata {
        ticket
        ticketReady
      }
    }
  }
`;

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(10, 10, 10, 0.86)',
    zIndex: '9998',
  },
  content: {
    maxWidth: '1080px',
    maxHeight: '560px',
    width: '50%',
    margin: 'auto',
    boxShadow: '0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)',
    padding: '0',
    transform: 'translate(0%, 0%)',
    zIndex: '9999',
  },
};

const modalStylesSp = {
  overlay: {
    backgroundColor: 'rgba(10, 10, 10, 0.86)',
    zIndex: '9998',
  },
  content: {
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: '80%',
    zIndex: '9999',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
  },
};

ReactModal.setAppElement('#___gatsby');

// Data Props Template
const TicketPage = (props) => {
  const url = props.data.site.siteMetadata;
  const [langage, setlangage] = useState({
    lang: 'ja',
    locale: 'ja',
  });
  // eslint-disable-next-line no-unused-vars
  const [json, setJson] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [holiday, setHoliday] = useState(null);
  const [rank, setRank] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const prevArrow = (
    <span className="icon is-large">
      <i className="fas fa-angle-left" />
    </span>
  );

  const nextArrow = (
    <span className="icon is-large">
      <i className="fas fa-angle-right" />
    </span>
  );

  // APIをfetch
  const dataFetch = () => {
    axios
      .get(TICKET_ENDPOINT(langage.locale))
      .then((results) => {
        const { data } = results;
        setTicketData({ api: data });
        setJson({ api: data });
      })
      .catch(() => {
      // console.log('APIエラー');
      });
  };

  useEffect(() => {
    dataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langage]);

  useEffect(() => {
    holidayFetch();
    rankFetch();
    /* eslint no-undef: 0 */
    window.addEventListener('wovnLangChanged', (evt) => {
      const langCode = WOVN.io.getCurrentLang().code;
      if (langCode === 'ja') {
        setlangage({
          lang: 'ja',
          locale: 'ja',
        });
      }
      if (langCode === 'en') {
        setlangage({
          lang: 'en',
          locale: 'en',
        });
      }
      if (langCode === 'ko') {
        setlangage({
          lang: 'ko',
          locale: 'kr',
        });
      }
      if (langCode === 'zh-CHS') {
        setlangage({
          lang: 'zh-CHS',
          locale: 'zh-hans',
        });
      }
      if (langCode === 'zh-CHT') {
        setlangage({
          lang: 'zh-CHT',
          locale: 'zh-hant',
        });
      }
    });
  }, []);

  const holidayFetch = () => {
    axios.get(HOLIDAY).then((result) => {
      const { data } = result;
      setHoliday({ api: data });
    }).catch((e) => {
      // console.warn(e)
    });
  };

  const rankFetch = () => {
    axios
      .get(RANK)
      .then((result) => {
        const { data } = result;
        const obj = {};
        data.forEach((item) => {
          // eslint-disable-next-line prefer-destructuring
          obj[item[0]] = item[1];
        });
        setRank(obj);
      })
      .catch((e) => {
        // console.warn(e)
      });
  };

  // 在庫状況をグラフィカルに
  const stockRate = (rate, few) => {
    if (rate === 0) {
      return 'none';
    }
    if (rate < 10 || few) {
      return 'few';
    }
    return 'stock';
  };

  const getFormatDate = (date) => `${date.getFullYear()}-${(`0${(date.getMonth() + 1)}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

  const getTileClassName = ({ date, view }) => {
    if (view !== 'month') {
      return null;
    }

    const isThursday = moment(date).format('d') === '6'; // 土曜日判定
    const isHoliday = (day) => holiday?.api[day]; // 休日判定
    const isTicket = (day) => ticketData?.api[day]; // チケット判定
    const day = getFormatDate(date);

    if (isThursday && isHoliday(day) && isTicket(day)) {
      return 'thursday holiday have-ticket';
    }
    if (isThursday && isHoliday(day)) {
      return 'thursday holiday';
    }
    if (isThursday && isTicket(day)) {
      return 'thursday have-ticket';
    }
    if (isHoliday(day) && isTicket(day)) {
      return 'holiday have-ticket';
    }
    if (isThursday) {
      return 'thursday';
    }
    if (isHoliday(day)) {
      return 'holiday';
    }
    if (isTicket(day)) {
      return 'have-ticket';
    }
    return false;
  };

  const getTileContent = ({ date, view }) => {

    // dateをフォーマット
    const day = `${date.getFullYear()}/${`${date.getMonth() + 1}`.slice(-2)}/${`${date.getDate()}`.slice(-2)}`;
    // ランクを取得
    const rankValue = rank !== null ? rank[day] : undefined;

    if (view !== 'month') {
      return null;
    }

    // rankがあれば表示
    if (rankValue) {
      return (
        <div className="tile-content">
          <p className={`color-${returnRankFilter(rankValue)[1]}`}>
            <span className="pc">{returnRankFilter(rankValue)[0]}</span>
            <span className="sp">{returnRankFilter(rankValue)[2]}</span>
          </p>
        </div>
      );
    }

    return (
      <div className="tile-content" />
    );
  };

  // ランクの色を返す
  const returnRankFilter = (rankValue) => {
    if (rankValue === 'ピーク') {
      return [rankValue, 'peek', 'P'];
    }
    if (rankValue === 'レギュラー') {
      return [rankValue, 'regular', 'R'];
    }
    if (rankValue === 'バリュー') {
      return [rankValue, 'value', 'V'];
    }
    return [rankValue, 'none', 'N'];
  };

  const onClickDay = (day) => {
    const formattedDay = getFormatDate(day);
    if (!ticketData?.api[formattedDay]) return;

    setSelectedDay(formattedDay);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const renderModalContent = () => {
    const dayData = ticketData && ticketData.api[selectedDay];
    if (!dayData) return null;
    return (
      <div className="panel">
        <p className="panel-heading has-text-centered">
          {moment(selectedDay).format('M月D日')}
        </p>
        {dayData?.map((data) => (
          <Collapsible
            className="panel-block panels"
            openedClassName="panel-block is-active panels"
            trigger={
              (
                <div className="trigger-content">
                  <span className="app__toggle-text" wovn-ignore="true">{data.name}</span>
                  <span className="panel-icon icon-open">
                    <i className="fas fa-chevron-down" />
                  </span>
                  <span className="panel-icon icon-close">
                    <i className="fas fa-chevron-up" />
                  </span>
                </div>
              )
            }
            key={data.uuid}
            transitionTime={200}
          >
            <h2 className="panel-heading has-text-centered subtitle is-6 is-marginless">
              只今の空き状況
            </h2>

            <div className="columns is-multiline is-gapless">
              {data.time_spans.map((span) => (
                <div className="column is-4" key={span.time_span_id}>
                  <div className="tic-state">
                    <span>
                      {`${span.start_str} - ${span.end_str}`}
                    </span>
                    <span className={`state-mark ${stockRate(span.inventory_rate, span.is_few_stock)}`} />
                  </div>
                </div>
              ))}
            </div>
            <div className="tic-btn has-text-centered">
              <div className="btn-wrap">
                <Button
                  href={`${url.ticket}#/order?lang=${langage.lang}&id=${data.uuid}`}
                  target="_blank"
                  className="btn-buy"
                  rel="noopener noreferrer"
                >
                  購入する
                </Button>
              </div>
            </div>
          </Collapsible>
        ))}
      </div>
    );
  };

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="section">
        <div className="container">
          <h3 className="title is-3 sub">{pageTitle}</h3>
          <div className="btn-group">
            <Link to="/ticket/how/" className="btn-def reverse">
              <span>チケット購入方法</span>
            </Link>
            {url.ticketReady === '1' ? (
              <a
                href={url.ticket}
                className="btn-def"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>チケット購入</span>
              </a>
            ) : null}
          </div>
          <div className="btn-group">
            <Link
              to="/news/teamlabforest/20200806695/"
              className="btn-def caution-ticket-btn"
            >
              <span>
                絶景3兄弟 SMBC日興証券・チームラボフォレスト 福岡 ｰ
                SBI証券をご購入の方は
                <br className="pc" />
                必ずご確認ください
              </span>
            </Link>
          </div>
          <div className="has-text-centered notification">
            <p className="mb-3">各日程の施設の空き状況を確認いただけます</p>
            <p className="is-size-7 mb-3">
              〇：余裕があります　△：残りわずか　×：空きなし
            </p>
            {rank ? (
              <>
                <p className="is-size-7 mb-2">
                  日程によって料金がレギュラーとピークの2段階に分かれます。ご予定の日がレギュラー日程かピーク日程かをカレンダーでご確認ください。
                </p>
                <p className="is-size-7">
                  料金は<Link to="/ticket/price">こちら</Link>
                  よりご参照ください。
                </p>
              </>
            ) : null}
            {mq('sp') && rank ? (
              <div className="price-desc mt-3">
                <span className="color-peek">P = ピーク</span>
                <span className="color-regular">R = レギュラー</span>
                {/* <span className="color-value">V = バリュー</span> */}
              </div>
            ) : null}
          </div>
          {url.ticketReady === '1' ? (
            <div className="calendar" wovn-ignore="true">
              <Calendar
                className="box is-paddingless"
                locale={langage.locale === 'kr' ? 'ko' : langage.locale}
                tileContent={getTileContent}
                tileClassName={getTileClassName}
                onClickDay={onClickDay}
                minDetail="month"
                minDate={new Date()}
                prev2Label={null}
                next2Label={null}
                nextLabel={nextArrow}
                prevLabel={prevArrow}
              />
            </div>
          ) : (
            <div className="inner-slim trans">
              <p className="has-text-align-center is-size-6">
                ただいまチケットの販売を停止しております
              </p>
            </div>
          )}
          <div className="inner-slim trans">
            <div className="btn-group">
              <Link to="/ticket/how/" className="btn-def reverse">
                <span>チケット購入方法</span>
              </Link>
              {url.ticketReady === '1' ? (
                <a
                  href={url.ticket}
                  className="btn-def"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>チケット購入</span>
                </a>
              ) : null}
            </div>
            <div className="btn-group mb-0">
              <Link
                to="/news/teamlabforest/20200806695/"
                className="btn-def caution-ticket-btn"
              >
                <span>
                  絶景3兄弟 SMBC日興証券・チームラボフォレスト 福岡 ｰ
                  SBI証券をご購入の方は
                  <br className="pc" />
                  必ずご確認ください
                </span>
              </Link>
            </div>
          </div>
          <ReactModal
            isOpen={modalOpen}
            style={mq('pc') ? modalStyles : modalStylesSp}
            shouldCloseOnOverlayClick
            onRequestClose={() => {
              setModalOpen(false);
              setSelectedDay(null);
            }}
          >
            <button
              type="button"
              className="delete"
              aria-label="close"
              onClick={closeModal}
            />
            {renderModalContent()}
          </ReactModal>
        </div>
      </section>
    </Layout>
  );
};

export default TicketPage;
